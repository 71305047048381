

$('.owl-carousel-popular').owlCarousel({
    autoplay:true,
    autoplayTimeout:4000,
    autoplayHoverPause:true,
    nav:false,
    pagination: false,
    dots: false,
    margin:20,
    stopOnHover:true,
    loop:true,
    responsive:{
        0:{
            items:2
        },
        600:{
            items:2
        },
        1000:{
            items:3
        },
        1600:{
            items:3
        }
}
});


var owlPopular = $('.owl-carousel-popular');
        owlPopular.owlCarousel();
        // Go to the next item
        $('.nextBtn-popular').click(function() {
            owlPopular.trigger('next.owl.carousel', [800]);
        })
        // Go to the previous item
        $('.prevBtn-popular').click(function() {
            // With optional speed parameter
            // Parameters has to be in square bracket '[]'
            owlPopular.trigger('prev.owl.carousel', [800]);
        });
