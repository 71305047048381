
if ( screen.width < 900 ){

    // When the user scrolls the page, execute myFunction
    window.onscroll = function() {myFunction()};
    
    // Get the navbar
    var navbarMob = document.getElementById("sticky_mobile");
    
    // Get the offset position of the navbar
    var sticky = navbarMob.offsetTop;
    
    // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function myFunction() {

        if($(document).scrollTop() > 220){

            navbarMob.classList.add("sticky")

        } else {

            navbarMob.classList.remove("sticky");

          }

    }
}
  
  
  
  $('#showRight').click(function() {
    $('.menu-right').toggleClass('right-open');
  });
  
  $('.backBtn').click(function() {
    $('.menu').removeClass('right-open');
    
  });

  $("body").click( function(e) {
    $('.menu').removeClass('right-open');
  });

  $("nav").click( function(e) {
        e.stopPropagation(); // this stops the event from bubbling up to the body
   });

