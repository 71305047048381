let helpFunctions = {
    disableScroll: function () {
        // Get the current page scroll position
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        (scrollLeft =
            window.pageXOffset || document.documentElement.scrollLeft),
            // if any scroll is attempted, set this to the previous value
            (window.onscroll = function () {
                window.scrollTo(scrollLeft, scrollTop);
            });
    },
    enableScroll: function () {
        window.onscroll = function () {};
    },
};

//#region Contact sidebar
let open = false;
function showSidebar() {
    open = !open;

    if ($(this).hasClass("focus-dark-bg")) {
        helpFunctions.enableScroll();
        $(".contact-sidebar-button").removeClass("move-left");
    }

    $(".contact-card").toggleClass("contact-card-show");
    $(".focus-dark-bg").toggleClass("d-block");

    if (open == true) {
        helpFunctions.disableScroll();
        $(this).addClass("move-left");
    } else if (open == false) {
        helpFunctions.enableScroll();
        $(this).removeClass("move-left");
    }
}
$(".contact-sidebar-button").on("click", showSidebar);
$(".focus-dark-bg").on("click", showSidebar);
//#endregion Contact sidebar



// $("body").click(function (e) {
//     $("body").removeClass("c-sidebar-open");
//     $(".darken-background").removeClass("darken-show");
//     $("html").removeClass("overflow-hidden");
// });
// $(".c-sidebar").click(function (e) {
//     e.stopPropagation(); // this stops the event from bubbling up to the body
// });
// $(".contact-sidebar-button").click(function (e) {
//     e.stopPropagation(); // this stops the event from bubbling up to the body
// });
// $(".contact-sidebar-button").click(function (e) {
//     $("body").toggleClass("c-sidebar-open");
//     $(".contact-sidebar-button").find("img").toggleClass("open");
//     $(".darken-background").toggleClass("darken-show");
//     $("html").toggleClass("overflow-hidden");
// });
