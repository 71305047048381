
    $(".filterss").on("click", ".collapse-link", function(){
        // $(".up-down-arrow").toggleClass("down-arrow");
        $(".up-down-arrow",this).toggleClass("down-arrow");
        
      });

      $(document).ready(function() {

        $(document.body).on('change', "input[type='radio'][name='subcategory'], input[type='radio'][name='category']", function(e) {
            
          var params = "?" + $("#filter :input[value!='']").filter(function(index, element) {
              return $(element).val() != '';
          }).serialize();
    
          $.get("getfilters?" + params, function(data) {
                $(".filterss").html(data);
            });
        });
        $(document.body).on('change', '#filter', function(e) {
            var search = new window.URLSearchParams(window.location.search);
            search = search.get('search');
          //console.log(e);
            //var inputs = $(this).find(":input").filter(function(){ return !this.value; }).attr("disabled", "disabled");
            var params = "?"+ "search="+ search + "&" + $("#filter :input[value!='']").filter(function(index, element) {
              return $(element).val() != '';
          }).serialize();
    
            //console.log(params);
            $.get(params + "&ajax=1", function(data) {
                $(".products").html(data);
                document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
                window.history.pushState("", "", params);
            });
            //this.submit();
        });
    
        
    });