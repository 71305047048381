
$('.owl-carousel-testimonials').owlCarousel({
    autoplay:true,
    autoplayTimeout:4000,
    autoplayHoverPause:true,
    nav:true,
    pagination: false,
    dots: false,
    margin:20,
    stopOnHover:true,
    loop:true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        1600:{
            items:1
        }
}
});

var owlTestimonials = $('.owl-carousel-testimonials');
        owlTestimonials.owlCarousel();
        // Go to the next item
        $('.nextBtn-num-test').click(function() {
            owlTestimonials.trigger('next.owl.carousel', [800]);
        })
        // Go to the previous item
        $('.prevBtn-num-test').click(function() {
            // With optional speed parameter
            // Parameters has to be in square bracket '[]'
            owlTestimonials.trigger('prev.owl.carousel', [800]);
        });